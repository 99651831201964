<template>
  <div>
    <router-link class="come-back" to="/admin/info_nko">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Информация об ОНК в субъектах РФ' : 'Информация об ОНК в субъектах РФ' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createInfoNko" ref="creteInfoNko">
      <div>
        <div class="content-sb">
          <p>Наименование палаты</p>
          <input placeholder="Введите название законопроекта" v-model="editData.name" type="text" name="name" class="input blue">
        </div>
        <div class="content-sb">
          <p>Федеральный округ<span>*</span></p>
          <select  @change="changeFederalDistrict" ref="changeFederalDistrict" required class="input blue" name="type">
            <option disabled>Выберите статус законопроекта</option>
            <option v-for="item in $store.getters.getStatusInfoNko"
                    :key="`getStatusCommissionUpdate${item.code}`"
                    :selected="editData.federal_district === item.id"
                    :value="item.id">
              {{ item.title }}</option>
          </select>
        </div>
        <div class="content-sb">
          <p>Дата истечения полномочий</p>
          <input  type="date" v-model="editData.first_meeting_date" name="first_meeting_date" class="input blue">
        </div>
        <div class="content-sb">
          <p>Основание создания </p>
          <input placeholder="Введите название" v-model="editData.reason" type="text" name="reason" class="input blue">
        </div>
        <div class="content-sb">
          <p>Субьект </p>
          <input placeholder="Введите название" v-model="editData.address" type="text" name="address" class="input blue">
        </div>
        <div class="content-sb">
          <p>Почта </p>
          <input placeholder="Введите название" v-model="editData.email" type="email" name="email" class="input blue">
        </div>
        <div class="content-sb">
          <p>Телефон </p>
          <input placeholder="Введите название" v-model="editData.phone" type="text" name="phone" class="input blue">
        </div>
        <div class="content-sb">
          <p>Ссылка на сайт</p>
          <input placeholder="Введите название" v-model="editData.site" type="text" name="site" class="input blue">
        </div>
        <div class="content-sb">
          <p>Срок </p>
          <input placeholder="Введите название" v-model="editData.term" type="text" name="term" class="input blue">
        </div>
        <div class="content-sb">
          <p>Председатель </p>
          <input placeholder="Введите название" v-model="editData.chairman" type="text" name="chairman" class="input blue">
        </div>
        <div class="content-sb">
          <p>Заместитель </p>
          <input placeholder="Введите название" v-model="editData.deputy_chairman" type="text" name="deputy_chairman" class="input blue">
        </div>
        <div class="content-sb">
          <p>Количество членов </p>
          <input placeholder="Введите название" v-model="editData.memberCount" min="0" type="number" name="memberCount" class="input blue">
        </div>
        <div class="content-sb">
          <p>Список региональные комиссии ОП</p>
          <tags @dataTags="dataTags" :startValue="editData.commission_list" />
        </div>
        <div class="content-sb">
          <p>Состав</p>
          <input v-model="editData.structure" placeholder="Введите состав" type="text" name="structure" class="input blue"/>
        </div>
      </div>
      <footer class="content-sb btns-create-form">
        <router-link to="/admin/info_nko" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import Tags from '@/components/Admin/Tags'

export default {
  name: 'createInfoNko',
  data () {
    return {
      editData: this.$route.query.type === 'edit' ? this.$store.getters.getDetailInfoNko : {
        commission_list: [],
        name: '',
        first_meeting_date: ''
      }
    }
  },
  methods: {
    createInfoNko () {
      const formData = new FormData()
      for (const key in this.editData) {
        var item = this.editData[key]
        if (key === 'tagList') {
        } else if (key === 'commission_list') {
          if (item.length > 0) formData.append('commission_list', JSON.stringify(this.editData.commission_list))
        } else if (item !== '' && item !== null) { formData.append(key, item) }
      }
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editInfoNko', { id: this.editData.id, data: formData })
      } else {
        this.$store.dispatch('AddInfoNko', formData)
      }
    },
    changeFederalDistrict (e) {
      this.editData.federal_district = e.target.value
      this.$store.dispatch('setSubjects', this.editData.federal_district)
    },
    dataTags (value) {
      this.editData.tagList = value
    }
  },
  watch: {
    '$store.getters.getStatusInfoNko' () {
      if (this.$route.query.type !== 'edit') {
        this.editData.federal_district = this.$store.getters.getStatusInfoNko[0].id
      } else {
        const validDate = this.editData.first_meeting_date.split('T')
        this.editData.first_meeting_date = validDate[0]
      }
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailInfoNko === null) {
      this.$router.push('/admin/info_nko')
    }
    this.$store.dispatch('setStatusInfoNko')
    if (this.$route.query.type === 'edit') {
      this.$store.dispatch('setSubjects', this.editData.federal_district)
    } else {
      this.$store.dispatch('setSubjects', 1)
    }
  },
  computed: {
    getSubjects () {
      return this.$store.getters.getSubjects
    }
  },
  components: {
    TricolorLine,
    Tags
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
